import {mapActions, mapGetters, mapMutations} from "vuex";
import {FETCH_USER_DATA, LINK_WITH_GOOGLE, SING_IN_WITH_GOOGLE} from '@/store/action-types';
import Vue from 'vue';
import GSignInButton from 'vue-google-signin-button';
import googleOneTap from 'google-one-tap';

Vue.use(GSignInButton);

export default {
  name: 'google-widget',
  data() {
    return {
      options: {
        client_id: '923551103702-5l5jrmq4plt12e3h4c0rqlr45cqn7bu1.apps.googleusercontent.com', // required
        auto_select: false, // optional
        cancel_on_tap_outside: false, // optional
        context: 'signin', // optional
      },
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_IDENTITY
      },
    }
  },
  mounted() {
    const options = {
      client_id: '923551103702-5l5jrmq4plt12e3h4c0rqlr45cqn7bu1.apps.googleusercontent.com', // required
      auto_select: false, // optional
      cancel_on_tap_outside: false, // optional
      context: 'signin', // optional
    };
    googleOneTap(options, (response) => {
      // Send response to server
      console.log('dsaasd',response);
    });
  },
  props: ['title'],
  components: {
    GSignInButton
  },
  methods: {
    ...mapActions({
      auth: `auth/${SING_IN_WITH_GOOGLE}`,
      link: `profile/${LINK_WITH_GOOGLE}`,
      fetchUser: `profile/${FETCH_USER_DATA}`,
      fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
    }),
    onSignInSuccess(googleUser) {
      console.log('user',googleUser)
      const token = googleUser.credential;

      if (this.$route.fullPath.includes('sing-in')
        || this.$route.fullPath.includes('register')
        || this.$route.fullPath.includes('login')
      ) {
        this.auth({token: token, provider: 'google'})
          .then(() => {
            this.fetchUser().then(() => {
              try {
                this.$router.push({name: 'profile'}).catch(() => {console.log()});
                this.fetchFavouriteList();
              } catch (e) {
                console.log(e);
              }
            });
            this.$toasted.success(this.$t('success'));
          })
          .catch(error => {
            console.log(error);
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      }
      if (this.$route.name.includes('buy-address')) {
        this.auth({token: token, provider: 'google'})
          .then(() => {
            this.fetchUser().then(() => {
              this.$toasted.success(this.$t('successAuth'));
            })
          })
          .catch(error => {
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      }
      if (this.$route.name.includes('user-data')) {
        this.link({token: token, provider: 'google'})
          .then(() => {
            this.fetchUser().then(() => {
              this.$toasted.success(this.$t('successLinkToAccount'));
              this.fetchFavouriteList();
            })
          })
          .catch(error => {
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      }

    },
    onSignInError(error) {
      console.log(error);
    },
    googleAuth(){
      window.google.accounts.id.initialize({
        client_id: this.options.client_id,
        callback: this.onSignInSuccess,
        auto_select: this.options.auto_select,
        cancel_on_tap_outside: this.options.cancel_on_tap_outside,
        context: 'signin',
      });
      window.google.accounts.id.prompt();
    },

  }
}
