import {mapActions, mapGetters, mapMutations} from "vuex";

import {dynamicHead} from '@/mixins/dynamic-head.js'
import google from "@/components/widgets/google/index.vue";
import facebook from "@/components/widgets/facebook/index.vue";

export default {
  name: "sign-in",
  mixins: [dynamicHead],
  components:{
    google,
    facebook,
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
    }
  },
  created() {
    this.fetchMeta('home').then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
  },
  computed:{
    ...mapGetters({
      meta: 'meta/meta',
    })
  },

  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
    }),
    ...mapMutations({
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    }
  }
}